@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply p-0 m-0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.iubenda-tp-btn-container {
  @apply hidden #{!important};
}

@layer utilities {
  // COMMON
  .section {
    @apply px-6 md:px-10 xl:px-16 xl:max-w-1680 mx-auto py-6 md:py-10 xl:py-16;

    // Full screen
    &.full {
      @apply px-0 max-w-none;
      .section-wrapper {
        @apply px-6 md:px-10 xl:px-16 xl:max-w-1680 mx-auto;
      }
    }

    // For narrow content
    &.narrow {
      @apply xl:max-w-7xl;
    }

    &.midspace {
      @apply py-16 md:py-32 xl:py-40;
    }

    &.smallspace {
      @apply py-10 md:py-20 xl:py-32;
    }

    &.bigspace {
      @apply py-28 md:py-48 xl:py-64;
    }

    // Reset
    &.no-space {
      @apply px-0 py-0 max-w-none;
    }
  }

  .section,
  .section-wrapper {
    // Spacing Y
    &.section-y-sm {
      @apply py-6 md:py-8 xl:py-14;
    }
    &.section-y-md {
      @apply py-8 md:py-12 xl:py-20;
    }
    &.section-y-lg {
      @apply py-8 md:py-16 xl:py-24;
    }
    &.section-y-xl {
      @apply py-10 md:py-16 xl:py-28;
    }
    &.section-y-2xl {
      @apply py-16 md:py-24 xl:py-40;
    }
    &.section-y-0 {
      @apply py-0;
    }

    &.space-y-md {
      @apply space-y-8 md:space-y-12 xl:space-y-20;
    }
    &.space-y-lg {
      @apply space-y-8 md:space-y-16 xl:space-y-24;
    }
    &.space-y-xl {
      @apply space-y-10 md:space-y-16 xl:space-y-28;
    }
  }
}

.clip-swiper-left {
  clip-path: inset(-100vw -100vw -100vw 0);
}

.section-x {
  @apply px-6 md:px-10 xl:px-16 xl:max-w-1680;
}

// SWIPER - Utility for .section-wrapper
.swiper {
  &.swiper-section-x {
    @apply px-6 md:px-10 xl:px-16;
  }
  &.noArrowsDesk {
    .swiper-button-prev,
    .swiper-button-next {
      @apply lg:hidden;
    }
  }
}

// COMMON - Spinner loader
.spinner {
  @apply border-t-primary ml-auto mr-auto w-12 h-12;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* PDP - Tabella guida taglie */
.table-sizeguide {
  @apply w-full text-sm;

  thead tr th {
    @apply text-center px-3 py-1 border bg-gray-200 border-b-transparent text-white uppercase font-normal;

    &:nth-child(2),
    &:nth-child(4),
    &:last-child {
      @apply bg-black;
    }

    &:nth-child(3),
    &:nth-child(5) {
      @apply bg-gray-600;
    }
  }

  tbody tr td {
    @apply text-center px-3 pt-1 border-gray-200;

    &:first-child {
      @apply text-left;
    }
  }
}

/* FOOTER - Form newlsetter */
@media only screen and (max-width: 375px) {
  .newsletter-custom-mobile > form {
    @apply flex-col items-start border-none pl-0;
    > {
      input {
        @apply w-full border-gray-600 rounded;
        &:focus {
          @apply outline-0 border-primary/60 #{!important};
        }
      }
      button {
        @apply mt-4;
      }
    }
  }
}

/* PDP - Lightbox gallery */
.lightbox-carousel > {
  .react-multiple-carousel__arrow--right {
    @apply bg-white shadow-sm shadow-gray-900/90;
    &::before {
      @apply text-primary text-lg;
    }
  }
  .react-multiple-carousel__arrow--left {
    &::before {
      @apply text-primary text-lg;
    }
    @apply bg-white shadow-sm shadow-gray-900/90;
  }
}

/* PDP - Carousel prodotti */
.my-carousel > {
  .react-multiple-carousel__arrow--right,
  .react-multiple-carousel__arrow--left {
    @apply bg-white shadow-sm;
  }

  .react-multiple-carousel__arrow--right::before,
  .react-multiple-carousel__arrow--left::before {
    @apply text-primary;
  }
}

// COMMON - Elementi da rimuovere
.rc-anchor,
.rc-anchor-alert,
.grecaptcha-badge {
  display: none !important;
}

.swiper {
  &.swiperScrollbar {
    @apply w-full h-full pb-16 xl:pb-0 overflow-clip overflow-y-visible #{!important};
    .swiper-scrollbar {
      @apply relative xl:hidden top-8 left-0 bg-black/10 #{!important};
      .swiper-scrollbar-drag {
        @apply bg-black;
      }
    }
  }
  &.swiperScrollbarTechs {
    @apply w-full h-full pb-16 xl:pb-0 overflow-visible #{!important};
    .swiper-scrollbar {
      @apply relative xl:hidden top-8 left-0 bg-white/10 #{!important};
      .swiper-scrollbar-drag {
        @apply bg-white;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    @apply bg-black rounded-full w-10 h-10 flex items-center justify-center;
    &:after {
      @apply text-base text-white;
    }
    &.swiper-button-disabled {
      @apply opacity-0;
    }
  }
}

.swiper .swiper-slide {
  height: auto;
}

.linkslist {
  .noborder {
    & > div {
      background-color: transparent !important;
      @apply transition-all duration-200;
    }
    &:hover {
      & > div {
        background-color: black !important;
        @apply transition-all duration-200;
      }
    }
  }
}

// Wrapper per video
.video-wrapper {
  position: relative;
  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Wrapper bg parallax
.bg-parallax {
  @apply absolute top-[-5%] left-0 right-0 h-[110%] z-0;
}

.materialsSwiper {
  .swiper-slide[data-soon='yes'] {
    .not-coming {
      @apply hidden;
    }
    .coming-soon {
      display: block !important;
    }
  }
}

.tech_formatter {
  h3 {
    @apply text-lg font-bold leading-tight tracking-uyn-xs uppercase mt-2 mb-1;
  }
}
