.paragraph {
  @apply mb-6;
  .table-auto {
    td {
      @apply pb-2 align-top;
    }
    td:first-child {
      @apply pr-3;
    }
  }

  ol {
    @apply list-decimal list-outside my-4;
    li {
      @apply pl-4 ml-6;
    }
  }
}
