// TITLES
.h1 {
  @apply text-[32px] md:text-[48px] xl:text-[56px] font-black leading-tight tracking-uyn uppercase;
}
.h2 {
  @apply text-3xl md:text-4xl xl:text-5xl font-black leading-tight tracking-uyn uppercase;
}
.h3 {
  @apply text-2xl xl:text-[32px] font-black leading-tight tracking-uyn uppercase;
}
.h4 {
  @apply text-xl xl:text-3xl font-bold leading-tight tracking-uyn uppercase;
}
.h5 {
  @apply text-lg xl:text-xl font-bold leading-tight tracking-uyn-xs uppercase;
}
.h6 {
  @apply text-sm xl:text-base font-bold leading-tight tracking-uyn-xs uppercase;
}
.lead-1 {
  @apply text-[48px] md:text-[64px] xl:text-[96px] font-black leading-tight tracking-uyn uppercase;
}
.lead-2 {
  @apply text-[40px] md:text-[56px] xl:text-[72px] font-black leading-tight tracking-uyn uppercase;
}

.h1 + *,
.h2 + *,
.h3 + * {
  @apply mt-2;
}

.h4 + *,
.h5 + * {
  @apply mt-1;
}

// Paragraphs
.text-body {
  @apply text-base md:text-[18px] leading-snug md:leading-normal tracking-wide font-normal;
}

// Common rules
html,
body {
  @apply text-paragraph text-black;
  font-family: 'DINPro', 'NotoSans', sans-serif;

  b,
  strong {
    @apply font-bold;
  }

  p {
    @apply text-paragraph;
  }

  .link {
    @apply underline cursor-pointer hover:opacity-80 transition-opacity;
  }
}

.font-safe {
  font-family: Inter, sans-serif;
}

// Menù
.text-menu-banner {
  @apply uppercase;
}
